/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FaArrowDown } from 'react-icons/fa';
import pic from '../Assets/pic.jpg';

function Project() {
  const projects = [
    {
      joined: 'Newgene',
      //   left: 'PRESENT',
      title: 'Ecofin Bank Website',
      link: 'https://www.ecofinservices.com/',
      para: '   I developed the main website for Ecofin Bank, focusing on a clean, professional design to showcase their short-term loan services for young professionals. The website was optimized for performance and user experience, highlighting key features like 24-hour loan approvals',
      //   skills: ['Javascript','React'],
    
    },
    {
      joined: 'Newgene',
      //   left: 'PRESENT',
      title: 'Namma Karnataka News Website·',
      link: 'https://nammakarnataka.co.in/',
      para: '   For Namma Karnataka News, a local news provider, I created a responsive, scalable website with features like live updates and an easy-to-use content management system. The platform delivers a seamless experience for readers across both mobile and desktop devices.',
      //   skills: ['Javascript'],

    },
    
  ];
  return (
    <div className="w-full flex flex-col sm:flex-row mt-16 sm:mt-24 overflow-hidden items-start pb-28">
      <div className="w-full sm:w-1/2 flex flex-col justify-center items-center">
        <div className="flex min-w-[70%] flex-col gap-2 ">
          <div className="text-5xl sm:text-7xl bold flex gap-4 ">  
            {/* <div className="h-[100px] bg-white rounded-full overflow-clip">    
              <img src={pic} className="h-full  aspect-square transition-transform mt-2  " alt="" />
            </div>   */}
            PROJECTS
          </div>
          {/* <div className="text-xl medium mt-4 ">Senior Frontend Engineer</div>
          <div className="text-xl regular opacity-65 w-[70%]">I build pixel-perfect, engaging, and accessible digital experiences.</div> */}

        </div>
      </div>
      <div className="sm:w-1/2  w-full">
   
        <div>
          {projects?.map((e) => (
            <a href={e.link} target="_blank" rel="noreferrer" className="w-full sm:w-[75%] flex mt-20 group relative hover:bg-white/10 hover:backdrop-blur-xl transition-all duration-300 rounded-lg shadow-lg py-5">
              <div className="w-[30%] px-6 pt-6 text-sm">
                <span className="medium opacity-55">
                  {e?.joined}
                
                </span>
              </div>
              <div className="w-[70%] py-4 px-2 flex flex-col gap-3">
                <span className="medium group-hover:text-teal-300 transition-colors duration-300 flex items-center gap-2">
                  {e?.title}
                  <FaArrowDown className="-rotate-[135deg] group-hover:translate-x-1 group-hover:-translate-y-1 transition-all duration-200" />
                </span>
                <span className="opacity-80">
                  {e?.para}

                </span>
                {/* <div className="flex items-center gap-4">
                  {e?.skills.map((el) => (
                    <span className="flex w-max items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300">
                      {el}
                    </span>
                  ))}
              
                </div> */}
            
              </div>
            </a>
          ))}

        </div>

      </div>
    </div>
  );
}

export default Project;
