/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { throttle } from 'lodash'; 
import { FaArrowDown,FaHeart,FaLinkedin } from 'react-icons/fa';
import { IoMdPhonePortrait } from 'react-icons/io';
import { SiGmail } from 'react-icons/si';
import AnimatedCursor from 'react-animated-cursor';
import { GoDotFill } from 'react-icons/go';
import * as animationData from './lottie.json';
import vd from './Assets/vbac.mp4';
import './App.css';
import Navbar from './Components/Navbar';
import Loadingpage from './Components/Loadingpage';
import Profile from './Components/Profile';
import Project from './Components/Projects';
import ely from './Assets/e.png';
import newge from './Assets/newgene.jpg';
import chegg from './Assets/chegg.png';
import sales from './Assets/sales.png';
import Skills from './Components/Skills';

export const baseURL = 'http://localhost:8000/api';

function App() {
  const animationContainer = useRef(null);
  const animationInstance = useRef(null);
  const [showNavbar, setShowNavbar] = useState(true); // State to toggle navbar visibility
  const [lastScrollTop, setLastScrollTop] = useState(0); // To track scroll direction
  const [loading, setLoading] = useState(true); // State for loading screen
  const [opacity, setOpacity] = useState(1);
  const [bgOpacity, setBgOpacity] = useState(0);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const work = [
    {
      src: ely,
      link: 'https://elyzian.in/',
      name: 'ELYZIAN',
    },
    {
      src: sales,
      link: 'https://salescaptain.com/',
      name: 'SALESCAPTAIN',
    },
    {
      src: newge,
      link: 'https://www.newgene.in/',
      name: 'NEWGENE',
    },
    {
      src: chegg,
      link: 'https://www.cheggindia.com/',
      name: 'CHEGG',
    },

  ];

  // Handle mouse move to update cursor position
  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    // Add mousemove event listener
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove); // Cleanup
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer when the component is unmounted
  }, []);

  // Lottie initialization after loading is complete
  useEffect(() => {
    if (!loading && animationContainer.current) {
      // Load Lottie animation only after loading screen is hidden
      animationInstance.current = lottie.loadAnimation({
        container: animationContainer.current, // HTML element
        renderer: 'svg',
        loop: false, // No looping
        autoplay: false, // No autoplay
        animationData, // Animation data from JSON
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice', // Makes sure it scales to fill the viewbox
        },
      });
    }

    // Cleanup function
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy(); // Clean up Lottie instance on unmount
      }
    };
  }, [loading]);

  // Function to handle scrolling and navbar visibility
  const handleScroll = () => {
    const { scrollTop } = document.documentElement;
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollFraction = scrollTop / scrollHeight;

    // Control Lottie animation based on scroll
    if (animationInstance.current) {
      const totalFrames = animationInstance.current.getDuration(true); // Get total frames
      const currentFrame = Math.floor(scrollFraction * totalFrames); // Map scroll to frame
      animationInstance.current.goToAndStop(currentFrame, true); // Move animation to frame and stop

      // Calculate scroll velocity
      const deltaScroll = scrollTop - lastScrollTop; // Change in scroll position
      const speed = Math.abs(deltaScroll) / 2; // Adjust speed based on your needs

      // Set Lottie animation speed based on scroll speed
      animationInstance.current.setSpeed(speed > 1 ? speed : 1); // Ensure minimum speed is 1
    }
    if (scrollTop > 3157) {
      const distanceFrom3157 = scrollTop - 3157;
      const newOpacity = Math.max(0, 1 - distanceFrom3157 / 1000); // Decrease opacity as distance from 3157 increases
      setOpacity(newOpacity);
      const newBgOpacity = Math.min(1, distanceFrom3157 / 500); // bgOpacity increases up to 1
      setBgOpacity(newBgOpacity);
    } else {
      setOpacity(1); // Reset opacity to 1 when below 3157
      setBgOpacity(0); // Reset bgOpacity to 0 when below 3157
    }
    // Show/hide navbar based on scroll direction
    if (scrollTop > lastScrollTop && scrollTop > 200) {
      setShowNavbar(false); // Hide navbar when scrolling down
    } else if (scrollTop < lastScrollTop) {
      setShowNavbar(true); // Show navbar when scrolling up
    }

    // Update the last scroll position
    setLastScrollTop(scrollTop);
  };

  // Throttle the handleScroll function to limit how often it can be called
  const throttledScrollHandler = throttle(handleScroll, 100); // Adjust the delay as needed

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', throttledScrollHandler);

    // Remove scroll event listener on unmount
    return () => {
      window.removeEventListener('scroll', throttledScrollHandler);
    };
  }, [lastScrollTop]); // Depend on lastScrollTop for detecting direction

  if (loading) {
    return (
      <div className="w-screen h-screen bg-black">
        <Loadingpage />
      </div>
    );
  }
  console.log('lastscrolltop',lastScrollTop,bgOpacity);

  return (
    <div 
      className="bg-[#e0e0e0] overflow-x-hidden"
    >
      {/* Navbar */}
      <Navbar showNavbar={showNavbar} />

      {/* Lottie Animation */}
      <div className="lottie-container" ref={animationContainer} style={{ opacity }}>
        <video
          className="background-video opacity-[0.3] sm:opacity-[0.18]"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={vd} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Scrollable Sections */}
      <div className="content-section flex-col items-center relative">
        <div className="flex flex-col items-start mt-28  text-[4em] sm:text-[8em] lg:text-[14em] xl:text-[15.9em] sm:mt-34 lg:mt-0 -space-y-8 sm:-space-y-20">
          <div className="bold slide-in-bottom-d  self-start">VIVEK's</div>
          <div className="bold slide-in-bottom-e ">PORTFOLIO</div>
        </div>
    
        <p className="semibold text-xs absolute right-4 bottom-3 uppercase">
          Keep scrolling
        </p>
      </div>

      <div id="about" className="content-section flex-col relative !z-10 !bg-transparent">
        <div className="-space-y-1 transition-all delay-200 uppercase text-sm">
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">Senior Frontend Developer</div>
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">4+ Years Experience</div>
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">Based Bangalore / Remote</div>
        </div>
        <div className="-space-y-1 transition-all delay-200 uppercase text-xs sm:text-sm top-[40%] absolute flex flex-col gap-7 w-[70%] sm:w-[40%] ">
          <div className="semibold transition-all delay-150 opacity-65 cursor-pointer">ABOUT</div>
          <div className="semibold text- transition-all delay-150  cursor-pointer">
            With over 4 years of frontend engineering expertise, I specialize in React.JS, Redux-Toolkit, Tailwind,
            Web Components and Express. I mostly enjoy building software in the sweet spot where design and engineering meet — things that look good but are also built well under the hood.
          </div>
          {/* <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">Based Bangalore / Remote</div> */}
        </div>
      </div>

      <div className="content-section flex-col relative !z-10 !bg-transparent items-end">
        <div className="-space-y-1 transition-all delay-200 uppercase text-xs sm:text-sm top-[0%] right-[5%] sm:right-[20%] absolute flex flex-col bg-black w-[60%] sm:w-[25%] text-white ">
          <div className="medium transition-all delay-150 opacity-65 flex items-center justify-between cursor-pointer p-2 px-4 border-b text-sm border-white">
            Thought
            <GoDotFill className="opacity-100 text-white" />
          </div>
          <div className="regular text- transition-all delay-150  cursor-pointer p-3">
            I turn coffee into code and pixels into experiences, one div at a time!

          </div>
          {/* <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">Based Bangalore / Remote</div> */}
        </div>
        <div className="-space-y-1 transition-all delay-200 uppercase text-xs sm:text-sm top-[57%] absolute flex flex-col gap-7 w-[70%] sm:w-[40%] right-[5%] ">
          <div className="semibold transition-all delay-150 opacity-65 cursor-pointer">MY VISION</div>
          <div className="semibold text- transition-all delay-150  cursor-pointer">
            My portfolio website will showcase my expertise as a frontend engineer and team leader, focusing on building intuitive, high-performance web solutions. With a modern, user-centric design, it will highlight my work across various industries, emphasizing clean, scalable code and strong user experience.
          </div>
          {/* <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">Based Bangalore / Remote</div> */}
        </div>
      </div>

      <div className="content-section flex-col relative !z-10 !bg-transparent items-start">
        <div className="-space-y-1 transition-all delay-200 uppercase text-xs sm:text-sm top-[20%] left-[3%] absolute flex flex-col bg-black w-[60%] sm:w-[25%] text-white ">
          <div className="medium transition-all delay-150 opacity-65 flex items-center justify-between cursor-pointer p-2 px-4 border-b text-sm border-white">
            Thought
            <GoDotFill className="opacity-100 text-white" />
          </div>
          <div className="regular text- transition-all delay-150  cursor-pointer p-3">
            Why be a magician when I can make things disappear with 
            {' '}
            {' '}
            <code className="lowercase">display: none;</code>
            {' '}
            ?
          </div>
        </div>
      </div>

      <div className="content-section flex-col relative  items-start -mt-40" style={{ backgroundColor: `rgba(0, 0, 0, ${bgOpacity > 0.173 ? bgOpacity : 0})` }}>
        <div className="-space-y-1 transition-all delay-200 uppercase text-[3.8em] sm:text-[10em] leading-[5rem] sm:leading-[8.5rem]">
          <div className="bold transition-all delay-150  cursor-pointer">VIEW</div>
          <div className="bold transition-all delay-150  cursor-pointer tracking-tighter">WORK</div>
          <div className="bold transition-all delay-150  cursor-pointer">EXPERIENCE.</div>
        </div>
      </div>
      <div className="content-section flex-col relative  !bg-black items-start " style={{ opacity: bgOpacity > 0.173 ? bgOpacity : 0 }}>
        {/* <div className="-space-y-1 transition-all delay-200 uppercase text-[10em] leading-[8rem]">
          <div className="bold transition-all delay-150  cursor-pointer">VIEW</div>
          <div className="bold transition-all delay-150  cursor-pointer tracking-tighter">WORK</div>
          <div className="bold transition-all delay-150  cursor-pointer">EXPERIENCE</div>
        </div> */}
      </div>
      <div
        className=" !h-[10vh] flex-col relative  !bg-black items-start"
      />
      {/* Custom Animated Cursor */}
    
      <AnimatedCursor
        innerSize={8}
        outerSize={40}
        color="255, 255, 255 "
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          'a',
          'button',
          '.link', // Custom classes you want the cursor to react to
        ]}
        // This enables the blend mode across the whole app
        outerStyle={{
          mixBlendMode: 'difference',
        }}
      />

      <div id="project" className="content-section flex-col relative !z-10 !bg-black text-white items-start">
        <div className="text-[3em] sm:text-[6em] bold  w-full flex items-center justify-between">
          <span>Work</span>
          <FaArrowDown className="text-[.6em]" />
        </div>
        <div className="flex flex-col sm:flex-row w-full gap-2">
          {work.map((item) => (
            <a href={item.link} target="_blank" rel="noreferrer" className="flex flex-col gap-3 w-full sm:w-[25%] mt-16 grayscale hover:grayscale-0 transition-all duration-150 ease-in">
              <div className="h-[27em] bg-white flex items-center justify-center">
                <img src={item?.src} className="max-w-[40%]" alt="" />
              </div>
              <div className="h-[] text- semibold opacity-65">
                {item.name}
              </div>
              <div className="h-[] w-[200px] px-3 py-1 text-white flex border border-white border-opacity-65 rounded relative">
                Visit
                <FaArrowDown className="-rotate-[135deg] absolute top-0 right-0  transition-all duration-200" />
      
              </div>
            </a>
          ))}
    
        </div>
        <div className="text-[3em] sm:text-[6em] bold  w-full flex items-center justify-between mt-36">
          <span>Profile</span>
          <FaArrowDown className="text-[.6em]" />
        </div>
        {/* Profile */}
        <Profile />

        <Project />
        <div className="text-[3em] sm:text-[6em] bold  w-full flex items-center justify-between mt-16 sm:mt-36">
          <span>Skills</span>
          <FaArrowDown className="text-[.6em]" />
        </div>
        <Skills />
      </div>

      <div id="info" className="content-section flex-col !p-0  relative !z-10 !bg-transparent">
        <div className="bold mt-16 text-5xl pl-4">
          Contact Me
        </div>
        <div className="mt-10">
          <div className="w-full group border-y-[1px] transition-all delay-200 border-[#01010140] hover:border-black py-6  flex flex-col sm:flex-row pl-3 sm:pl-0 gap-1 sm:gap-0 sm:items-center">
            <div className="w-[30%] hidden sm:block ">
              {/* hi */}
            </div>
            <div className="w-[30%] flex items-center gap-2 text-lg semibold">
              <FaLinkedin className="text-blue-600" />
              {' '}
              LinkedIn

            </div>
            <a href="https://www.linkedin.com/in/viveksingh5614/" target="_blank" rel="noreferrer" className="w-[30%] hover:text-blue-500 hover:underline">
              https://www.linkedin.com/in/viveksingh5614/
            </a>
            <div className="w-[10%] group-hover:scale-in-bl">
              <FaArrowDown className="-rotate-[135deg]  transition-all duration-200" />
            </div>
          </div>
          <div className="w-full border-b-[1px] transition-all delay-200 border-[#01010140] hover:border-black py-6  flex flex-col sm:flex-row pl-3 sm:pl-0 gap-1 sm:gap-0 sm:items-center">
            <div className="w-[30%] hidden sm:block">
              {/* hi */}
            </div>
            <div className="w-[30%] flex items-center gap-2 text-lg semibold">
              <SiGmail />
              Gmail
            </div>
            <div className="w-[30%]">
              vivek.cradle1@gmail.com
            </div>
            <div className="w-[10%]">
              <FaArrowDown className="-rotate-[135deg]  transition-all duration-200" />
            </div>
          </div>
          <div className="w-full border-b-[1px] transition-all delay-200 border-[#01010140] hover:border-black py-6  flex flex-col sm:flex-row pl-3 sm:pl-0 gap-1 sm:gap-0 sm:items-center">
            <div className="w-[30%] hidden sm:block">
              {/* hi */}
            </div>
            <div className="w-[30%] flex items-center gap-2 text-lg semibold">
              <IoMdPhonePortrait />
              phone
            </div>
            <div className="w-full sm:w-[30%]">
              +91 8294644246
            </div>
            <div className="w-[10%]">
              <FaArrowDown className="-rotate-[135deg]  transition-all duration-200" />
            </div>
          </div>
        </div>

        <div className="flex mt-48 border-y group border-[#01010140]">
          <a href="https://www.linkedin.com/in/viveksingh5614/" target="_blank" rel="noreferrer" className="w-[calc(100%-135px)]   text-5xl sm:text-9xl bold border-r border-[#01010140] py-3 pl-4">LET's CONNECT</a>
          <div className="w-[135px] flex items-center justify-center transition-all duration-200 delay-150 text-7xl group-hover:bg-black group-hover:text-white">
            <FaArrowDown className="-rotate-[135deg]  transition-all duration-200" />
          </div>
        </div>
        <div className="flex  h-[130px]  group bg-[#010101]  items-center justify-center">
          <div className="text-white text-base bold regular flex items-center gap-2 border-r border-[#01010140] py-3 pl-4">
            THANK YOU
            <FaHeart />
          </div>
        
        </div>
      </div>
      
    </div>
  );
}

export default App;
