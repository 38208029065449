/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FaArrowDown } from 'react-icons/fa';
import pic from '../Assets/pic.jpg';

function Profile() {
  const details = [
    {
      joined: '2023',
      left: 'PRESENT',
      title: 'Elyzian',
      link: 'https://elyzian.in/',

      pos: 'Co-founder / Founding Frontend Developer',
      para: '   As Co-founder at Elyzian and Senior Frontend Developer, I coordinated a team of six developers to build and maintain critical components used to construct Elyzian’s frontend and backend across the entire product. I collaborated closely with developers and designers to implement and advocate for best practices in web accessibility',
      skills: ['React','Redux-Toolkit','Tailwind','Express','Javascript','HTML','CSS'],

    },
    {
      joined: '2022',
      left: '2023',
      title: ' SalesCaptain',
      link: 'https://salescaptain.com/',

      pos: 'Senior Frontend Developer',
      para: '   At Sales Captain Lead, a text-based marketing platform, I built, styled, and shipped high-quality Saas, design systems, web apps, and digital experiences for a diverse range of projects for clients including Honda, Volkswagen, and many SMBs based in the US. I provided leadership within the engineering department through close collaboration, knowledge sharing, and spearheaded the development of internal tools.',
      skills: ['React','Redux-Toolkit','Tailwind','Javascript','HTML','CSS'],
  
    },
    {
      joined: '2020',
      left: '2022',
      title: 'Newgene Security Solutions',
      link: 'https://www.newgene.in/',

      pos: 'Frontend Developer',
      para: '  At Newgene Security Solutions, I worked with clients like Ecofin Bank, Namma Karnataka News, and Clothing Care Express, where I was responsible for building their websites and integrating frontend systems with backend services using REST APIs. I ensured seamless communication between interfaces, delivering robust, secure, and scalable web solutions for these diverse industries',
      skills: ['React','Javascript','HTML','CSS'],
    
    },
    {
      joined: '2019',
      left: '2020',
      title: ' Chegg',
      pos: 'Frontend Developer',
      link: 'https://www.cheggindia.com/',

      para: '   At Chegg, as a Frontend Engineer, I modernized their existing systems built with basic HTML, CSS, and JavaScript by transitioning them to React. This upgrade enhanced performance, scalability, and maintainability, ensuring a more dynamic and responsive user experience across their platform.',
      skills: ['React','HTML','CSS'],
    
    },
  ];
  return (
    <div className="w-full flex flex-col sm:flex-row mt-16 sm:mt-24 overflow-hidden items-start">
      <div className="w-full sm:w-1/2 flex flex-col justify-center items-center">
        <div className="flex min-w-[70%] flex-col gap-2 ">
          <div className="text-5xl sm:text-7xl bold flex gap-4 ">  
            {/* <div className="h-[100px] bg-white rounded-full overflow-clip">    
              <img src={pic} className="h-full  aspect-square transition-transform mt-2  " alt="" />
            </div>   */}
            Vivek Kumar
          </div>
          <div className="text-xl medium mt-4 ">Senior Frontend Engineer</div>
          <div className="text-xl regular opacity-65 w-full sm:w-[70%]">I build pixel-perfect, engaging, and accessible digital experiences.</div>

        </div>
      </div>
      <div className="w-full sm:w-1/2 mt-16 sm:mt-0 ">
        <div className=" flex flex-col gap-10 w-full sm:w-[90%] xl:w-[75%] text-pretty">
          <p className="text-lg">Back in 2018, I decided to dive into coding by creating custom web projects, and I quickly tumbled head first into the world of web development. Fast-forward to today, and I’ve had the privilege of building software for a text-based marketing platform, a fintech company, a local news provider, and leading the frontend team at a start-up.</p>

          <p className="text-lg">These days, my main focus is building accessible and high-performance user interfaces for our customers at Elyzian. I find the most joy in creating solutions that blend beautiful design with robust engineering—ensuring that things look great and work flawlessly under the hood. Along the way, I've also led a team of developers and mentored peers to create scalable and impactful web products.</p>

          <p className="text-lg">In my free time, I love playing video games, traveling, and connecting with impactful founders and peers in my domain to exchange ideas and stay inspired.</p>
      
        </div>
        <div>
          {details?.map((e) => (
            <a href={e.link} target="_blank" rel="noreferrer" className="w-full sm:w-[90%] xl:w-[75%] flex mt-20 group relative hover:bg-white/10 hover:backdrop-blur-xl transition-all duration-300 rounded-lg shadow-lg py-5">
              <div className="w-[30%] px-6 pt-6 text-sm">
                <span className="medium opacity-55">
                  {e?.joined}
                  {' '}
                  - 
                  {' '}
                  {e?.left}
                </span>
              </div>
              <div className="w-[70%] py-4 px-2 flex flex-col gap-3">
                <span className="medium group-hover:text-teal-300 transition-colors duration-300 flex items-center gap-2">
                  {e?.title}
                  <FaArrowDown className="-rotate-[135deg] group-hover:translate-x-1 group-hover:-translate-y-1 transition-all duration-200" />
                </span>
                <span className="opacity-80 text-teal-300 group-hover:text-white">
                  {e?.pos}

                </span>
                <span className="opacity-80">
                  {e?.para}

                </span>
                <div className="flex items-center gap-4 w-full pr-1 flex-wrap">
                  {e?.skills.map((el) => (
                    <span className="flex w-max items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300">
                      {el}
                    </span>
                  ))}
              
                </div>
            
              </div>
            </a>
          ))}

        </div>

      </div>
    </div>
  );
}

export default Profile;
