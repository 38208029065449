/* eslint-disable react/no-array-index-key */
import React from 'react';
import './components.css';
import { AiFillApi } from 'react-icons/ai';
import { MdWebStories } from 'react-icons/md';
import {
  FaBootstrap, FaCss3Alt, FaFigma, FaGithub, FaHtml5, FaJs, FaReact, 
} from 'react-icons/fa';
import {
  SiRedux, SiTailwindcss, SiMui,
} from 'react-icons/si';

function Skills() {
  const skills = [
    { icon: <FaReact className="text-blue-500 text-4xl" />, name: 'ReactJS' },
    { icon: <SiRedux className="text-purple-500 text-4xl" />, name: 'Redux Toolkit' },
    { icon: <MdWebStories className="text-blue-500 text-4xl" />, name: 'Responsive Web Design' },
    { icon: <AiFillApi className="text-green-500 text-3xl" />, name: 'REST APIs' },
    { icon: <SiTailwindcss className="text-teal-400 text-4xl" />, name: 'Tailwind CSS' },
    { icon: <SiMui className="text-blue-300 text-4xl" />, name: 'Material UI' },
    { icon: <FaBootstrap className="text-purple-500 text-4xl" />, name: 'Bootstrap' },
    { icon: <FaCss3Alt className="text-blue-500 text-4xl" />, name: 'CSS' },
    { icon: <FaFigma className="text-pink-500 text-4xl" />, name: 'Figma' },
    { icon: <FaGithub className="text-gray-300 text-4xl" />, name: 'Git/Github' },
    { icon: <FaHtml5 className="text-orange-500 text-4xl" />, name: 'HTML' },
    { icon: <FaJs className="text-yellow-500 text-4xl" />, name: 'JavaScript' },
  ];

  return (
    <div className="relative w-full overflow-hidden py-10">
      <div className="animate-marquee  flex w-full items-center gap-20 text-white">
        {skills.map((skill, index) => (
          <div key={index} className="flex items-center gap-2">
            {skill.icon}
            <span>{skill.name}</span>
          </div>
        ))}
        {/* Duplicate the skills for seamless animation */}
        {skills.map((skill, index) => (
          <div key={index + skills.length} className="flex items-center gap-2">
            {skill.icon}
            <span>{skill.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
