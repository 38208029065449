import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { IoArrowForward } from 'react-icons/io5';
import * as animationData from '../Assets/sound.json'; // Make sure the path is correct
import vcv from '../Assets/VCV.pdf';
import soundFile from '../Assets/Interstellar.mp3'; // Import your audio file
import './components.css';

function Navbar({ showNavbar }) {
  const [currentTime, setCurrentTime] = useState('');
  const [isSoundOn, setIsSoundOn] = useState(false); // State for sound control
  const lottieContainer = useRef(null);
  const audioRef = useRef(new Audio(soundFile)); // Create a ref for the audio element

  // Function to format the time
  const formatTime = (date) => {
    const options = {
      hour: '2-digit', minute: '2-digit', hour12: true, 
    };
    return date.toLocaleTimeString([], options).replace(' am', ' AM').replace(' pm', ' PM');
  };

  useEffect(() => {
    // Initialize Lottie animation
    const animationInstance = lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    });

    // Set the current time initially
    const now = new Date();
    setCurrentTime(formatTime(now));

    // Update the time every second
    const interval = setInterval(() => {
      const updatedTime = new Date();
      setCurrentTime(formatTime(updatedTime));
    }, 1000);

    // Cleanup interval and animation on component unmount
    return () => {
      clearInterval(interval);
      animationInstance.destroy(); // Clean up animation instance
    };
  }, []);

  const toggleSound = () => {
    setIsSoundOn((prev) => {
      const newSoundState = !prev;
      if (newSoundState) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        }); // Play the audio if it was paused
      } else {
        audioRef.current.pause(); // Pause the audio if it was playing
      }
      return newSoundState; // Update the sound state
    });
  };

  return (
    <div className={`header text-base flex px-5 py-4 h-[72px] ${showNavbar ? 'slide-in-top ' : 'slide-out-top'}`}>
      <div className="w-full sm:w-[52%] flex justify-between sm:justify-normal sm:gap-28">
        <div className="flex flex-col -space-y-1">
          <div className="semibold text-[12px] lg:text-[17px]">Portfolio Website</div>
          <div className="flex gap-1 text-xs lg:text-sm items-center regular">
            <span className="">
              {currentTime}
              {' '}
              , IST
              {' '}
            </span> 
            <span className="h-2 w-2 bg-black rounded-full animate-pulse" />
            <span>BLR</span>
          </div>
        </div>
        <a href={vcv} target="_blank" rel="noopener noreferrer" className="transition-all delay-150 flex sm:hidden gap-2 items-center hover:bg-black hover:text-white rounded-full text-xs p-1 px-2">
          <div className="semibold cursor-pointer">DOWNLOAD RESUME</div>
          <div className="semibold -rotate-45 cursor-pointer"><IoArrowForward /></div>
        </a>
        <div className="hidden sm:block">
          <div className="flex gap-2 text-[12px] items-center semibold">
            {/* Lottie Animation */}
            <div 
              ref={lottieContainer} 
              className="w-[25px] h-[20px] visible" 
            />
            <button 
              onClick={toggleSound} 
              className="cursor-pointer"
              type="button"
            >
              SOUND : 
              {' '}
              {isSoundOn ? 'ON' : 'OFF'}
            </button>
          </div>
        </div>
      </div>
      <div className="w-[48%] pl-20 text-[12px] hidden sm:flex justify-between items-center ">
        <a href="#about" className="-space-y-2 transition-all delay-200 ">
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">ABOUT</div>
        </a>
        <a href="#info" className="-space-y-2 transition-all delay-200 ">
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">INFO</div>
        </a>
        <a href="#project" className="-space-y-2 transition-all delay-200 ">
          <div className="semibold transition-all delay-150 hover:opacity-65 cursor-pointer">PROJECTS</div>
        </a>
        <a href={vcv} target="_blank" rel="noopener noreferrer" className="transition-all delay-150 flex gap-2 items-center hover:bg-black hover:text-white rounded-full p-2 px-4">
          <div className="semibold cursor-pointer">DOWNLOAD RESUME</div>
          <div className="semibold -rotate-45 cursor-pointer"><IoArrowForward /></div>
        </a>
      </div>
    </div>
  );
}

export default Navbar;
