/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { IoArrowForward } from 'react-icons/io5';
import logo from '../Assets/new.jpg';
import * as animationData from '../Assets/bg.json';
import './components.css';

function Loadingpage() {
  const [showReveal, setshowReveal] = useState(false);
  const lottieContainer = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowReveal(true); 
    }, 2000);
    
    return () => clearTimeout(timer); // Cleanup the timer when the component is unmounted
  }, []);

  useEffect(() => {
    // Initialize Lottie animation
    const animationInstance = lottie.loadAnimation({
      container: lottieContainer.current, // HTML element to render the animation
      renderer: 'svg',
      loop: true, // Loop the animation
      autoplay: true, // Autoplay the animation
      animationData, // Animation data JSON
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice', // Preserve aspect ratio
      },
    });
    // Cleanup interval and animation on component unmount
    return () => {
      animationInstance.destroy(); // Clean up animation instance
    };
  }, []);
  return (
    <div className="w-full h-full flex items-center justify-center flex-col relative overflow-clip">
      <div 
        ref={lottieContainer} 
        className=" absolute top-[60%] sm:top-[23%] opacity-75 "
      />
      <div className="h-[250px] z-10 absolute transition-all ring-4  slide-bck-top rounded-full overflow-clip border-[1px] border-opacity-65 border-white ">
        <img src={logo} className="h-full  aspect-square " alt="" />
      </div>
      {showReveal && (
      <div className="hidden sm:block text-white text-[50px] bold mt-10 z-20">
        <div className="wrapper first-text">Hi , I'm Vivek.</div>
        <div className="wrapper second-text">
          <span>Welcome to my Portfolio</span>
        </div>
     
      </div>
      )}
 
    </div>
  );
}

export default Loadingpage;
